<template>
    <VVVLayoutNew>
        <template #page-title>
            Tutorials
        </template>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader v-if="isAdmin || isSuperAdmin" class="d-md-flex d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-start mb-3 mb-md-0 align-items-center">
                    <MDBBtn color="primary" @click="tutorialModal = true" class="fw-bold" size="sm">
                        <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                        Add
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div v-if="tutorialList.length === 0 && !isLoading" class="text-center m-auto d-flex justify-content-center align-items-center">
                    <vue-feather type="alert-circle" :stroke-width="2.5" size="18px" class="me-2" />
                    <span>No tutorials found.</span>
                </div>
                <div v-else class="d-block d-lg-flex flex-wrap gap-4 justify-content-center px-0 py-3 overflow-auto"
                    id="table-container">
                    <div v-if="isLoading" class="image-container d-flex justify-content-center align-items-center">
                        <div class="loader"></div>
                        <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                    </div>
                    <div v-else v-for="(tutorial, index) in tutorialList" :key="index"
                        class="d-block d-lg-flex flex-column gap-3 mb-lg-0 mb-4 mx-auto"
                        style="flex: 1 1 450px; max-width: 450px;cursor: pointer;">
                        <div class="video-container my-3 mb-md-2 mb-4">
                            <iframe width="100%" :height="isMobile ? '220' : '253'" class="responsive-iframe"
                                :src="getYoutubeVideoIframe(tutorial.link)" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                            </iframe>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-block" @click="openVideoModal(tutorial.link, tutorial.description)">
                                <p class="m-auto text-left fw-bold text-truncate video-title" style="font-size: 15px;">
                                    {{ tutorial.title }}
                                </p>
                                <p class="m-auto text-left text-truncate video-description" style="font-size: 14px;">
                                    {{ tutorial.description }}
                                </p>
                            </div>
                            <MDBDropdown dropstart v-model="tutorial.showOptions" v-if="isAdmin || isSuperAdmin">
                                <MDBDropdownToggle class="p-1 bg-transparent shadow-0 no-arrow">
                                    <MDBIcon @click="toggleOptions(index)" iconStyle="fas" icon="ellipsis-v"
                                        style="color: black;" />
                                </MDBDropdownToggle>
                                <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                                    <MDBDropdownItem tag="button" @click="openEditModal(tutorial)"
                                        class="d-flex align-items-center">
                                        <vue-feather type="edit" :stroke-width="2.5" size="15px" class="me-2" />
                                        <span>Edit Video Details</span>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem tag="button" @click="openDeleteModal(tutorial.id)"
                                        class="d-flex align-items-center">
                                        <vue-feather type="trash-2" :stroke-width="2.5" size="16px" class="me-2" />
                                        <span>Delete Video</span>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    </div>
                </div>
                <div class="overflow-auto">
                    <Paginate v-if="tutorialList.length !== 0" :page-count="tutorialListPagination.totalPages || 0"
                        :click-handler="handlePage" :prev-text="'Previous'" :next-text="'Next'"
                        :container-class="'pagination'" first-last-button
                        style="margin-top: 0px; margin-left: unset; overflow: auto;padding-top: 15px;cursor: pointer;">
                    </Paginate>
                </div>
            </MDBCardBody>
        </MDBCard>
        <MDBModal id="viewTutorialModal" tabindex="-1" labelledby="viewTutorialModalLabel" v-model="viewTutorialModal"
            scrollable :static-backdrop="true" size="lg">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="tutorialModalLabel"> View Video Details </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="py-3">
                <div class="d-flex justify-content-center align-items-center mt-3" v-if="isLoading">
                    <div class="image-container d-flex justify-content-center align-items-center">
                        <div class="loader"></div>
                        <img src="@/assets/VVV/vvv-loader.png" alt="isLoading image">
                    </div>
                </div>
                <div v-else class="d-flex flex-column justify-content-center overflow-auto mx-auto"
                    style="flex: 1 1 600px; max-width: 600px;cursor: pointer;">
                    <div class="video-container mt-3 mt-md-1">
                        <iframe width="100%" :height="isMobile ? '200' : '341'" class="responsive-iframe"
                            :src="youtubeVideoIframe" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                    <div class="pt-3">
                        <p class="text-left fw-bold mb-2" style="font-size: 15px;">{{
                            youtubeVideo.title }}</p>
                        <p class="text-left" style="font-size: 14px;">{{
                            viewDescription }}</p>
                    </div>
                </div>
            </MDBModalBody>
        </MDBModal>
        <MDBModal id="tutorialModal" tabindex="-1" labelledby="tutorialModalLabel" v-model="tutorialModal" scrollable
            :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="tutorialModalLabel"> Add Video Tutorial </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4">
                <div class="d-flex justify-content-center flex-column">
                    <div class="input-group w-100">
                        <MDBBtn color="primary" size="sm">
                            <i class="fab fa-youtube fa-fw pb-1"></i>
                        </MDBBtn>
                        <input v-model="videoUrl" type="text" class="form-control" placeholder="YouTube Video URL"
                            @keyup.enter="previewVideo(videoUrl)" />
                        <MDBBtn class="btn btn-primary fw-bold" type="button" @click="previewVideo(videoUrl)">
                            Preview
                        </MDBBtn>
                    </div>
                </div>
                <template v-if="youtubeVideo.title">
                    <div class="d-flex justify-content-center align-items-center mt-3" v-if="isLoading">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/VVV/vvv-loader.png" alt="isLoading image">
                        </div>
                    </div>
                    <div v-else class="d-flex flex-column justify-content-center overflow-auto">
                        <div class="video-container my-3">
                            <iframe width="600" :height="isMobile ? '217' : '250'" class="responsive-iframe"
                                :src="youtubeVideoIframe" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                        <p class="text-left fw-bold mb-0" style="font-size: 15px;">{{
                            youtubeVideo.title }}</p>
                    </div>
                    <textarea v-model="description" class="form-control mt-3" rows="3"
                        placeholder="Description"></textarea>
                </template>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="tutorialModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="addVideoTutorial">Confirm Add
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBModal id="editTutorialModal" tabindex="-1" labelledby="editTutorialModalLabel" v-model="editTutorialModal"
            scrollable :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="editTutorialModalLabel"> Edit Video Tutorial </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4">
                <div class="d-flex justify-content-center flex-column">
                    <div class="input-group w-100">
                        <MDBBtn color="primary" size="sm">
                            <i class="fab fa-youtube fa-fw pb-1"></i>
                        </MDBBtn>
                        <input v-model="editVideoUrl" type="text" class="form-control" placeholder="YouTube Video URL"
                            @keyup.enter="previewVideo(editVideoUrl)" />
                        <MDBBtn class="btn btn-primary fw-bold" type="button" @click="previewVideo(editVideoUrl)">
                            Preview
                        </MDBBtn>
                    </div>
                </div>
                <template v-if="youtubeVideo.title">
                    <div class="d-flex justify-content-center align-items-center mt-3" v-if="isLoading">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/VVV/vvv-loader.png" alt="isLoading image">
                        </div>
                    </div>
                    <div v-else class="d-flex flex-column justify-content-center overflow-auto">
                        <div class="video-container my-3">
                            <iframe width="600" :height="isMobile ? '217' : '250'" class="responsive-iframe"
                                :src="youtubeVideoIframe" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                        <p class="text-left fw-bold mb-0" style="font-size: 15px;">{{
                            youtubeVideo.title }}</p>
                    </div>
                </template>
                <textarea v-model="editDescription" class="form-control mt-3" rows="3"
                    placeholder="Description"></textarea>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="editTutorialModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="updateVideoTutorial">Update</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteVideoModalLabel" v-model="deleteModal">
            <MDBModalHeader>
                <MDBModalTitle class="fw-bold" id="deleteVideoModalLabel"> Delete Video </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>Are you sure you want to delete this video?</MDBModalBody>
            <MDBModalFooter class="gap-2">
                <MDBBtn class="btn fw-bold" size="sm" @click="deleteModal = false">Close</MDBBtn>
                <MDBBtn class="btn btn-danger fw-bold" size="sm" @click="deleteTutorial">
                    Confirm Delete
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
    </VVVLayoutNew>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBIcon, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBToast, MDBDropdown, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import VVVLayoutNew from "@/views/VVVNew/VVVLayoutNew.vue";
import { ref, onMounted, computed, onBeforeUnmount, watch } from "vue";
import { useTitle } from "@vueuse/core";
import { storeToRefs } from "pinia";
import Paginate from "vuejs-paginate-next";
import { useYoutubeStore } from "@/store/youtubeStore";
import { isYouTubeUrl, extractVideoId } from "@/helpers/youtubeScraper";
import axios from "axios";
import VueFeather from 'vue-feather';
import { GetVideoTutorialList } from "@/services/Tutorials/GetTutorialList";
import { useUserDetailStore } from "../../store/userDetailStore";

useTitle("Tutorials | Very Viral Videos");

const userDetailStore = useUserDetailStore();
const { isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);

const youtubeStore = useYoutubeStore();
const { youtubeVideo } = storeToRefs(youtubeStore);

const videoUrl = ref(youtubeVideo.value.url);
const description = ref("");

const isLoading = ref(false);
// const searchValue = ref("");

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
    title: "",
});

const tutorialList = ref({});
const tutorialListPagination = ref({});
const currentPage = ref(1);

const handlePage = (currentPageValue) => {
    currentPage.value = currentPageValue;
    getVideoTutorialList(currentPageValue);
}

const getVideoTutorialList = async (pageNumber = 1) => {
    isLoading.value = true;
    try {
        const response = await GetVideoTutorialList(pageNumber);
        tutorialList.value = response.data.map(tutorial => ({
            ...tutorial,
            showOptions: false,
        }));
        tutorialListPagination.value = response.pagination;
    } catch (error) {
        console.error("Error fetching tutorials:", error);
    } finally {
        isLoading.value = false;
    }
};

onMounted(() => {
    getVideoTutorialList(currentPage.value);
});

const previewVideo = async (ytLink) => {
    if (ytLink === "") {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "Please enter a YouTube URL to preview.";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        return;
    }
    if (!isYouTubeUrl(ytLink)) {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "Please enter a valid YouTube URL.";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        return;
    }
    isLoading.value = true;
    youtubeStore.setYoutubeVideo(ytLink)
    isLoading.value = false;
};

const youtubeVideoIframe = computed(() => `https://www.youtube.com/embed/${youtubeVideo.value.id}?rel=0&color=white&disablekb=1&modestbranding=1&cc_load_policy=1`);

const getYoutubeVideoIframe = (videoLink) => {
    if (!videoLink) {
        return '';
    }

    const videoId = extractVideoId(videoLink);
    if (!videoId) {
        console.error("Could not extract video ID from link:");
        return '';
    }

    return `https://www.youtube.com/embed/${videoId}?rel=0&color=white&disablekb=1&modestbranding=1&cc_load_policy=1`;
};

const tutorialModal = ref(false);
const addVideoTutorial = async () => {
    const tutorialData = {
        title: youtubeVideo.value.title,
        description: description.value,
        link: youtubeVideo.value.url,
    };

    try {
        const response = await axios.post("/api/Tutorials", tutorialData);
        if (response.status === 422) {
            toastObject.value.state = true;
            toastObject.value.title = "Error!";
            toastObject.value.message = "Video already exists.";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        } else if (response.status === 200 || response.status === 201) {
            tutorialModal.value = false;
            toastObject.value.title = "Success!";
            toastObject.value.state = true;
            toastObject.value.message = "Video added successfully!";
            toastObject.value.color = "success";
            toastObject.value.icon = "fas fa-check fa-lg me-2";
            getVideoTutorialList(currentPage.value);
            videoUrl.value = "";
            youtubeVideo.value.id = '';
            youtubeVideo.value.title = "";
            youtubeVideo.value.url = "";
            description.value = "";
        } else {
            toastObject.value.state = true;
            toastObject.value.title = "Error!";
            toastObject.value.message = "An unexpected error occurred.";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        }
    } catch (error) {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "Error adding video tutorial:", error.message;
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
    }
};

const toggleOptions = (index) => {
    tutorialList.value[index].showOptions = !tutorialList.value[index].showOptions;
};

const deleteModal = ref(false);
const tutorialToDelete = ref(null);

const openDeleteModal = (id) => {
    tutorialToDelete.value = id;
    deleteModal.value = true;
};

const deleteTutorial = async () => {
    try {
        await axios.patch(`/api/Tutorials/${tutorialToDelete.value}/Status`);
        toastObject.value = {
            title: "Success!",
            state: true,
            message: "Video deleted successfully!",
            color: "success",
            icon: "fas fa-check fa-lg me-2",
        };
        deleteModal.value = false;
        getVideoTutorialList(currentPage.value);
    } catch (error) {
        toastObject.value = {
            title: "Error!",
            state: true,
            message: `Error deleting video: ${error.message}`,
            color: "danger",
            icon: "fas fa-exclamation-circle fa-lg me-2",
        };
    }
};

const editTutorialModal = ref(false);
const editVideoUrl = ref("");
const editDescription = ref("");
const currentTutorialId = ref(null);

const openEditModal = (tutorial) => {
    editVideoUrl.value = tutorial.link;
    editDescription.value = tutorial.description;
    currentTutorialId.value = tutorial.id;
    editTutorialModal.value = true;
};

const updateVideoTutorial = async () => {
    const updatedData = {
        title: youtubeVideo.value.title,
        description: editDescription.value,
        link: editVideoUrl.value,
    };

    try {
        const response = await axios.patch(`/api/Tutorials/${currentTutorialId.value}`, updatedData);
        if (response.status === 200) {
            toastObject.value = {
                title: "Success!",
                state: true,
                message: "Video updated successfully!",
                color: "success",
                icon: "fas fa-check fa-lg me-2",
            };
            editTutorialModal.value = false;
            getVideoTutorialList(currentPage.value);
        }
    } catch (error) {
        toastObject.value = {
            title: "Error!",
            state: true,
            message: `Error updating video: ${error.message}`,
            color: "danger",
            icon: "fas fa-exclamation-circle fa-lg me-2",
        };
    }
};

const isMobile = ref(window.innerWidth <= 767);
const updateResolution = () => {
    isMobile.value = window.innerWidth <= 767;
};

onMounted(() => {
    window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateResolution);
});

const viewTutorialModal = ref(false);
const viewDescription = ref("");

const openVideoModal = (videoLink, videoDescription) => {
    viewDescription.value = videoDescription;
    if (isYouTubeUrl(videoLink)) {
        youtubeStore.setYoutubeVideo(videoLink);
        viewTutorialModal.value = true;
    } else {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "Invalid YouTube URL.";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
    }
};

watch([tutorialModal, editTutorialModal], ([addModalValue, editModalValue]) => {
    if (!addModalValue || !editModalValue) {
        videoUrl.value = '';
        youtubeVideo.value.id = '';
        youtubeVideo.value.title = '';
        youtubeVideo.value.url = '';
        description.value = '';
    }
});

watch(viewTutorialModal, (viewModalValue) => {
    if (!viewModalValue) {
        videoUrl.value = '';
        youtubeVideo.value.id = '';
        youtubeVideo.value.title = '';
        youtubeVideo.value.url = '';
    }
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 55vh;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.video-container {
    display: flex;
    justify-content: center;
}

.video-title {
    max-width: 400px;
}

.video-description {
    max-width: 400px;
}

@media screen and (max-width: 767px) {
    .video-container {
        width: 100%;
        height: 25vh;
        display: flex;
        justify-content: center;
    }

    .video-title {
        max-width: 260px;
    }

    .video-description {
        max-width: 260px;
    }
}
</style>
